import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import "tachyons/css/tachyons.min.css";
import "../../css/common.css";

import Seo from "../../components/SeoComponent";
import Header from "../../components/Header";
import TitlePage from "../../components/ui/TitlePage";
import SectionTextPage from "../../components/ui/SectionTextPage";
import SectionImage from "../../components/ui/SectionImage";
import ListText from "../../components/ui/ListText";
import SectionImagePlayer from "../../components/ui/SectionImagePlayer";
import ReadMore from "../../components/ReadMore";
import Footer from "../../components/Footer";

const Freight = () => {
  const { t } = useTranslation();

  return (
    <>
      <Seo
        title={t("page-solutions.freight.metadata.name")}
        description={t("page-solutions.freight.metadata.description")}
      />
      <Header />
      <TitlePage category={t("page-solutions.freight.category")} title={t("page-solutions.freight.title")} />
      <SectionImage
        imageHD="plane-face.jpg"
        imageMD="plane-face-md.jpg"
        imageSD="plane-face-sd.jpg"
        alt="solution-freight"
      />
      <SectionTextPage>
        <p className="section-title">{t("page-solutions.freight.s1-1")}</p>
        <p>{t("page-solutions.freight.s1-p1")}</p>
        <p dangerouslySetInnerHTML={{ __html: t("page-solutions.freight.s1-p2") }}></p>
        <p>{t("page-solutions.freight.s1-p3")}</p>
        <p>{t("page-solutions.freight.s1-p4")}</p>
      </SectionTextPage>

      <SectionTextPage mode="dark">
        <p className="section-subtitle" style={{ marginTop: 0 }}>
          {t("page-solutions.freight.s2-1")}
        </p>
        <ListText text={t("page-solutions.freight.s2-l1")} />
        <ListText text={t("page-solutions.freight.s2-l2")} />
        <ListText text={t("page-solutions.freight.s2-l3")} />
        <ListText text={t("page-solutions.freight.s2-l4")} />
        <p>{t("page-solutions.freight.s2-p1")}</p>
      </SectionTextPage>

      <SectionTextPage>
        <p className="section-title" style={{ marginTop: 0 }}>
          {t("page-solutions.freight.s3-1")}
        </p>
        <p>{t("page-solutions.freight.s3-p1")}</p>
        <p>{t("page-solutions.freight.s3-p2")}</p>
        <p>{t("page-solutions.freight.s3-p3")}</p>
      </SectionTextPage>

      <SectionImagePlayer imageHD="freight-boat.jpg" imageMD="freight-boat-md.jpg" imageSD="freight-boat-sd.jpg" />

      <SectionTextPage>
        <p className="section-title" style={{ marginTop: 0 }}>
          {t("page-solutions.freight.s4-1")}
        </p>
        <p>{t("page-solutions.freight.s4-p1")}</p>
        <p>{t("page-solutions.freight.s4-p2")}</p>
        <p>{t("page-solutions.freight.s4-p3")}</p>
      </SectionTextPage>

      <SectionTextPage mode="dark">
        <p className="section-subtitle" style={{ marginTop: 0 }}>
          {t("page-solutions.freight.s5-1")}
        </p>
        <ListText text={t("page-solutions.freight.s5-l1")} />
        <ListText text={t("page-solutions.freight.s5-l2")} />
        <ListText text={t("page-solutions.freight.s5-l3")} />
        <ListText text={t("page-solutions.freight.s5-l4")} />
        <ListText text={t("page-solutions.freight.s5-l5")} />
        <ListText text={t("page-solutions.freight.s5-l6")} />
        <ListText text={t("page-solutions.freight.s5-l7")} />
      </SectionTextPage>

      <SectionTextPage>
        <p>{t("page-solutions.freight.s6-p1")}</p>
        <p className="section-title" style={{ paddingTop: 40 }}>
          {t("page-solutions.freight.s6-1")}
        </p>
        <p>{t("page-solutions.freight.s6-p2")}</p>
        <p className="section-subtitle">{t("page-solutions.freight.s6-2")}</p>
        <ListText text={t("page-solutions.freight.s6-l1")} />
        <ListText text={t("page-solutions.freight.s6-l2")} />
      </SectionTextPage>

      <ReadMore title={t("page-solutions.freight.readmore")} news={[]} />

      <Footer />
    </>
  );
};

export default Freight;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
